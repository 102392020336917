import React, { Fragment } from "react";
import { Button, Header, Modal } from "semantic-ui-react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import axios from "../../utils/axiosInstance";

import InputC from "../../components/InputC/InputC";

const RegisterModal = (props) => {

    const { setContext } = props;

    const initialValues = {
        full_name: "",
        email: "",
        contact_no: "",
        password: "",
        re_password: "",
        showPassword: false
    }

    const schema = yup.object().shape({
        full_name: yup.string().min(2, "Name must be at least 2 characters long").required("Name is required!"),
        email: yup.string().email("Invalid Email").required("Email is required!"),
        contact_no: yup.string().matches(/^(?:\+88|01)?(?:\d{11}|\d{13})$/, "Phone number is not valid")
            .required("Phone number is required"),
        password: yup.string().min(6, "Password must be at least 6 characters long").required("Password is required!"),
        re_password: yup.string().oneOf([yup.ref("password"), null], "Passwords must match!").required("Re-Password is required!")
    });

    const handleSubmit = async (values, fr) => {

        try {

            const postData = {...values};
            delete postData.showPassword;
            
            const { data } = await axios.post("/auth/sign-up", postData);
        
            if (data.signUpSuccess) {
    
                toast.success(data.msg);
                setContext("login");
    
            } else {
                toast.error(data.msg);
            }

        } catch (err) {
            console.log(err.response);
        }
    }

    return (
        <Fragment>
            <Header size="large">
                Save your Information
            </Header>
            <Modal.Content>
                <Modal.Description>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={schema}
                        onSubmit={handleSubmit}
                    >
                        {(fr) => (
                            <Form>
                                <InputC
                                    size="big"
                                    fluid name="full_name"
                                    placeholder="Enter your full name"
                                    type="text"
                                    onChange={fr.handleChange}
                                    onBlur={fr.handleBlur}
                                    error={fr.errors.full_name && fr.touched.full_name}
                                    errorMsg={fr.errors.full_name}
                                />

                                <InputC
                                    size="big"
                                    fluid name="email"
                                    placeholder="Enter your email"
                                    type="email"
                                    onChange={fr.handleChange}
                                    onBlur={fr.handleBlur}
                                    error={fr.errors.email && fr.touched.email}
                                    errorMsg={fr.errors.email}
                                />

                                <InputC
                                    size="big"
                                    fluid name="contact_no"
                                    placeholder="Enter your phone no."
                                    type="text"
                                    onChange={fr.handleChange}
                                    onBlur={fr.handleBlur}
                                    error={fr.errors.contact_no && fr.touched.contact_no}
                                    errorMsg={fr.errors.contact_no}
                                />

                                <InputC
                                    icon={{
                                        name: fr.values.showPassword ? "eye slash" : "eye", link: true,
                                        onClick: () => fr.setFieldValue("showPassword", !fr.values.showPassword)
                                    }}
                                    autoComplete="off"
                                    size="big"
                                    fluid name="password"
                                    placeholder="Enter your password"
                                    type={fr.values.showPassword ? "text" : "password"}
                                    onChange={fr.handleChange}
                                    onBlur={fr.handleBlur}
                                    error={fr.errors.password && fr.touched.password}
                                    errorMsg={fr.errors.password}
                                />

                                <InputC
                                    icon={{
                                        name: fr.values.showPassword ? "eye slash" : "eye", link: true,
                                        onClick: () => fr.setFieldValue("showPassword", !fr.values.showPassword)
                                    }}
                                    autoComplete="off"
                                    size="big"
                                    fluid name="re_password"
                                    placeholder="Confirm your password"
                                    type={fr.values.showPassword ? "text" : "password"}
                                    onChange={fr.handleChange}
                                    onBlur={fr.handleBlur}
                                    error={fr.errors.re_password && fr.touched.re_password}
                                    errorMsg={fr.errors.re_password}
                                />

                                <Button
                                    fluid
                                    color="yellow"
                                    type="submit"
                                    size="big"
                                    disabled={fr.isSubmitting}
                                    loading={fr.isSubmitting}
                                >
                                    Register
                                </Button>

                                <div onClick={() => setContext("login")} className="Register--links">
                                    <span>
                                        Already have an account? {" "}
                                        Login
                                    </span>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Description>
            </Modal.Content>
        </Fragment>
    );
}

export default RegisterModal;