import React, { useContext, useState, Fragment } from "react";
import { Modal, Message, Button, Icon, Header, Grid, Card, Image, Radio } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { EditorContext } from "../../shared/context/editor-context";
import { UtilContext } from "../../shared/context/util-context";

import printIcon from "../../assets/art-and-design.svg";
import frameIcon from "../../assets/frame.svg";
import collageIcon from "../../assets/collage.svg";
import albumIcon from "../../assets/album.svg";

import "./ImageUploader.scss";

const ImageUploader = () => {

    const history = useHistory();

    const { 
        uploaderOpen, 
        closeUploader, 
        insertNewImages, 
        originalImages,
        setMethodGlobal 
    } = useContext(EditorContext);

    const { 
        uploaderContext, 
        setUploaderContext, 
        method, 
        setMethod,
    } = useContext(UtilContext);

    const [uploading, setUploading] = useState(false);

    const onUploaderClose = () => {
        setMethod("");
        setUploading(false);
        setUploaderContext("method");
        closeUploader();
    }

    const onFileSelect = async (event) => {
        
        setUploading(true);

        let fileList = [...event.target.files];
        let filteredFileList = [];
        const dupes = [];
        const lessThan500 = [];

        const minSize = 500 * 1000; // 500 kb in bytes

        if (method === "album" && (fileList.length + originalImages.length) < 50) {
            toast.error("Minimum total photo count has to be 50 for albums");
            return setUploading(false);
        }

        if (originalImages.length > 0) {

            filteredFileList = fileList.filter(file => {
                
                const found = originalImages.find(img => img.name === file.name);

                if (!found) {

                    if (file.size < minSize) {
                        lessThan500.push(file.name);
                        return false;
                    }

                    return true;
                }

                dupes.push(found.name);

                return false;
            });

        } else {

            filteredFileList = fileList.filter(file => {

                if (file.size < minSize) {
                    
                    lessThan500.push(file.name);
                    return false;
                }

                return true;
            });
        }

        if (lessThan500.length > 0) {
            toast.warn("Some of the photos were removes as they were less than 500 kb");
        }

        if (filteredFileList.length > 0) {

            const result = await insertNewImages("new", filteredFileList);
    
            if (result) {
                
                history.push(`/photo-library/?type=${method}`);
                onUploaderClose();
            }

        } else {

            history.push(`/photo-library/?type=${method}`);
            onUploaderClose();
        }
    }

    const selectMethod = (type) => {

        setMethod(type);
        setMethodGlobal(type);
    } 

    const proceedNext = () => {

        if (method === "collage") {
            history.push("/collages");
            return onUploaderClose();
        }

        setUploaderContext("upload");
    }

    let header = null;
    let body = null;

    if (uploaderContext === "upload") {

        header = (
            <Header size="large">
                Upload Photos from Your Device
            </Header>
        );

        body = (
            <div className="uploader-container">

                {
                    !uploading ?
                    <Fragment>
                        <div className="uploader-center">
                            <div style={{ marginBottom: "30px" }}>
                                <input
                                    accept="image/*"
                                    hidden
                                    multiple
                                    id="contained-button-file"
                                    type="file"
                                    onChange={(event => onFileSelect(event))}
                                />
                                <label htmlFor="contained-button-file">
                                    <Button 
                                        icon 
                                        size="large" 
                                        color="yellow"
                                        as="span" 
                                        labelPosition="right"
                                    >
                                        Upload Photos
                                        <Icon name="images" />
                                    </Button>
                                </label>
                            </div>
                        </div>

                        {
                            method === "album" &&
                            <Message
                                header="Minimum 50 photos"
                                content="For album, minimum 50 photos must be selected"
                            />
                        }
        
                        <Message
                            header="Want to upload multiple photos?"
                            content="When selecting photos, 
                                hold the Control key (Ctrl) or Command key 
                                on Mac and click on each photo."
                        />
                    </Fragment> :
                    <Message icon>
                        <Icon name="circle notched" loading />
                        <Message.Content>
                            <Message.Header>Just a few moments</Message.Header>
                            We are uploading the photos for you.
                        </Message.Content>
                    </Message>
                }
            </div>
        );

    } else if (uploaderContext === "method") {

        header = (
            <Header size="large">
                Choose a Service
            </Header>
        );

        body = (
            <div className="preview-container">
                <Grid stackable centered columns="equal">
                    <Grid.Row columns="2">
                        <Grid.Column>
                            <Card onClick={() => selectMethod("print")} className="Method--card" fluid>
                                <Card.Content>
                                    <div className="Method--card--content">
                                        <Radio
                                            name="method"
                                            value="print"
                                            checked={method === "print"}
                                        />
                                        <Image size="mini" src={printIcon} />
                                        <h3>Print Photos</h3>
                                    </div>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        <Grid.Column>
                            <Card onClick={() => selectMethod("frame")} className="Method--card" fluid>
                                <Card.Content>
                                    <div className="Method--card--content">
                                        <Radio
                                            name="method"
                                            value="frame"
                                            checked={method === "frame"}
                                        />
                                        <Image size="mini" src={frameIcon} />
                                        <h3>Frame Photos</h3>
                                    </div>
                                </Card.Content>
                            </Card>
                        </Grid.Column>    
                        <Grid.Column>
                            <Card onClick={() => selectMethod("collage")} className="Method--card" fluid>
                                <Card.Content>
                                    <div className="Method--card--content">
                                        <Radio
                                            name="method"
                                            value="collage"
                                            checked={method === "collage"}
                                        />
                                        <Image size="mini" src={collageIcon} />
                                        <h3>Photo Collages</h3>
                                    </div>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        <Grid.Column>
                            <Card onClick={() => selectMethod("album")} className="Method--card" fluid>
                                <Card.Content>
                                    <div className="Method--card--content">
                                        <Radio
                                            name="method"
                                            value="album"
                                            checked={method === "album"}
                                        />
                                        <Image size="mini" src={albumIcon} />
                                        <h3>Photo Albums</h3>
                                    </div>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid centered>
                            <Grid.Row>
                                <Grid.Column width="16">
                                    <div>
                                        <Button 
                                            icon 
                                            disabled={!method} 
                                            onClick={proceedNext} 
                                            color="yellow"
                                            labelPosition="right" 
                                            size="large"
                                        >
                                            Proceed Next
                                            <Icon name="arrow right" />
                                        </Button>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }

    return (
        <Modal
            closeIcon
            size="small"
            open={uploaderOpen}
            onClose={onUploaderClose}
        >
            {header}
            <Modal.Content>
                <Modal.Description>
                    {body}
                </Modal.Description>
            </Modal.Content>
        </Modal>
    );
}

export default ImageUploader;
