import React, { useState, useContext, Fragment } from "react";
import { Menu, Button, Image, Icon, Container } from "semantic-ui-react";         
import { Link, withRouter } from "react-router-dom";

import { ThemeContext } from "../../shared/context/theme-context";
import { UtilContext } from "../../shared/context/util-context";

import "./NavBar.scss";
import logo from "../../assets/logo.png";

const NavBar = (props) => {

    const { theme } = useContext(ThemeContext);
    const { inviteModalControl } = useContext(UtilContext);
    
    const [activeItem, setActiveItem] = useState("home");

    const setMenuActive = (_, { name }) => setActiveItem(name);

    return (
        <div className="NavBar">
            <Menu style={{ background: "#17202A" }} inverted fixed="top" borderless>
                <Container>

                    <Menu.Item className="NavBar--logo">
                        <Link to="/" onClick={() => setActiveItem("logo")}>
                            <Image src={logo} style={{ height: "60px" }} />
                        </Link>
                    </Menu.Item>

                    <Menu.Menu position="right">
                        {
                            theme.isComputer &&
                            <Fragment>
                                <Menu.Item
                                    as="a"
                                    href="/#service"
                                >
                                    Services
                                </Menu.Item>
                                
                                <Menu.Item
                                    as={Link}
                                    to="/prices"
                                    name="prices"
                                    active={activeItem === "prices"}
                                    onClick={setMenuActive}
                                >
                                    Pricing
                                </Menu.Item>
                                
                                <Menu.Item
                                    as={Link}
                                    to="/blogs"
                                    name="blogs"
                                    active={activeItem === "blogs"}
                                    onClick={setMenuActive}
                                >
                                    Blog
                                </Menu.Item>
                                <Menu.Item
                                    onClick={() => inviteModalControl(true)}
                                    className="NavBar--menu-item"
                                    content="Invite"
                                />
                                <Menu.Item>
                                    <div>
                                        <Button
                                            as={Link}
                                            to="/contact"
                                            color="yellow"
                                        >
                                            Contact Us
                                        </Button>
                                    </div>
                                </Menu.Item>
                            </Fragment>
                        }
                        {
                            !theme.isComputer &&
                            <Menu.Item onClick={() => props.setMobileNav(true)}>
                                <div style={{ cursor: "pointer" }}>
                                    <Icon name="bars" size="large" />
                                </div>
                            </Menu.Item>
                        }
                    </Menu.Menu>
                </Container>
            </Menu>
        </div>
    );
}

export default withRouter(NavBar);