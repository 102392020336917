import React, { useContext } from "react";
import { Sidebar, Responsive, Menu, Header, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

// import { AuthContext } from "../../shared/context/auth-context";
import { UtilContext } from "../../shared/context/util-context";

const MobileNavBar = (props) => {

    const { inviteModalControl } = useContext(UtilContext);
    // const { logout } = useContext(AuthContext);

    const handleClose = () => props.setMobileNav(false);

    // const handleLogout = () => {
    //     logout(true);
    //     handleClose();
    // }

    return (
        <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
            <Sidebar
                as={Menu}
                animation="overlay"
                onHide={handleClose}
                vertical
                visible={props.mobileNav}
                direction="right"
                width="wide"
            >
                <div className="SideNavBar">
                    <div onClick={handleClose} className="menu-close-icon">
                        <Icon name="close" size="large" />
                    </div>
                    <div className="SideNavBar--header">
                        <Header size="large" dividing>Menu</Header>
                    </div>

                    <Menu.Item onClick={handleClose} as={Link} to="/prices" icon="money" content="Pricing" />
                    <Menu.Item onClick={() => inviteModalControl(true)} icon="send" content="Invite" />
                </div>
            </Sidebar>
        </Responsive>
    );
}

export default MobileNavBar;