import React, { useContext, useState } from "react";
import { withRouter } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"

import NavBar from "../components/Navbar/NavBar";
import MobileNavBar from "../components/Navbar/MobileNavBar";
import Account from "../components/RegisterModal/Account";
import ImageUploader from "../components/ImageUploader/ImageUploader";
import InviteModal from "../components/InviteModal/InviteModal";

import { AuthContext } from "../shared/context/auth-context";
import { ThemeContext } from "../shared/context/theme-context";

import "./Layout.scss";

const Layout = (props) => {

    const path = props.location.pathname;

    const [mobileNav, setMobileNav] = useState(false);

    const { isLoggedIn } = useContext(AuthContext);
    const { theme } = useContext(ThemeContext);

    return (
        <div className="Layout">
            <ToastContainer autoClose={3000} />
            {
                (path !== "/editor" && path !== "/editor/") &&
                <div className="Layout--navbar">
                    <NavBar setMobileNav={setMobileNav} />
                </div>
            }

            {
                theme.isMobile &&
                <MobileNavBar mobileNav={mobileNav} setMobileNav={setMobileNav} />
            }
            
            { 
                !isLoggedIn && 
                <Account /> 
            }

            <InviteModal /> 
            
            <ImageUploader />
            
            <main className="Layout--body">
                {props.children}
            </main>
        </div>
    );
}

export default withRouter(Layout);
