import React, { useState, createContext } from "react";
import uniqid from "uniqid";

export const EditorContext = createContext({
    uploaderOpen: false,
    openRegistrationModal: false,
    methodGlobal: "",
    setOpenRegistrationModal: () => {},
    openUploader: () => {},
    setMethodGlobal: () => {},
    closeUploader: () => {},
    originalImages: [],
    selectedImages: [],
    priceTableGlobal: [],
    stagingSaved: [],
    selectedFrame: null,
    insertSelectedFrame: () => {},
    setPriceTableGlobal: () => {},
    insertSelectedImages: () => {},
    insertNewImages: () => {},
    patchImages: () => {},
    saveStaging: () => {},

    // ALBUM FLOW
    collagePhotos: [],
    setCollagePhotos: () => {}
});

export const EditorContextProvider = (props) => {

    const [uploaderOpen, setUploaderOpen] = useState(false);
    const [openRegistrationModal, setOpenRegistrationModal] = useState(false);

    const [methodGlobal, setMethodGlobal] = useState("");
    const [originalImages, setOriginalImages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [priceTableGlobal, setPriceTableGlobal] = useState([]);
    const [stagingSaved, setStagingSaved] = useState([]);

    const [selectedFrame, setSelectedFrame] = useState(null);

    const [collagePhotos, setCollagePhotos] = useState([]);

    const openUploader = () => setUploaderOpen(true);
    const closeUploader = () => setUploaderOpen(false);

    const getImageMetaDataAsync = (img) => {

        const src = URL.createObjectURL(img);

        return new Promise((resolve, reject) => {

            const domImg = new Image();

            domImg.onload = () => {

                img.dimension = {
                    width: domImg.width,
                    height: domImg.height
                };

                if (img.dimension.width > img.dimension.height) {
                    img.dimension.type = "landscape";
                } else if (img.dimension.width < img.dimension.height) {
                    img.dimension.type = "portrait";
                } else {
                    img.dimension.type = "even";
                }

                if (!img.id) {
                    img.id = `${uniqid()}-${img.name}`;
                }

                img.preview = src;

                return resolve(img);
            };

            domImg.onerror = reject;
            domImg.src = src;
        });
    }

    const insertNewImages = async (type, images, img_id, value) => {

        let updatedImages = null;
        const imagesList = [...images];
        
        if (type === "new") {

            const imagesWithMetaData = await Promise.all(
                imagesList.map(img => getImageMetaDataAsync(img))
            );

            updatedImages = [...originalImages, ...imagesWithMetaData];
            setOriginalImages(updatedImages);

            return true;

        } else if (type === "edit") {
            
            updatedImages = [...stagingSaved];
            
            const imagesWithMetaData = await Promise.all(
                imagesList.map(img => getImageMetaDataAsync(img))
            );

            const foundImgIndex = updatedImages.findIndex(size => img_id === size.id);

            if (foundImgIndex !== -1) {

                updatedImages[foundImgIndex].copy.image = imagesWithMetaData[0];
                setStagingSaved(updatedImages);
            }

            return imagesWithMetaData[0];

        } else if (type === "frame") {

            updatedImages = [...originalImages];
            
            const imagesWithMetaData = await Promise.all(
                imagesList.map(img => getImageMetaDataAsync(img))
            );

            const foundImgIndex = updatedImages.findIndex(img => img_id === img.id);

            if (foundImgIndex !== -1) {

                updatedImages[foundImgIndex] = imagesWithMetaData[0];
                updatedImages[foundImgIndex].dimension.type = value;
                setOriginalImages(updatedImages);
            }

            return imagesWithMetaData[0];
        }
    }

    const patchImages = (images) => setOriginalImages(images);

    const saveStaging = (stage) => setStagingSaved(stage);
    
    const insertSelectedImages = (selected) => setSelectedImages(selected);

    const insertSelectedFrame = (frame) => setSelectedFrame(frame);
    
    return (
        <EditorContext.Provider 
            value={{ 
                methodGlobal,
                uploaderOpen,
                openRegistrationModal,
                setMethodGlobal,
                openUploader,
                closeUploader,
                originalImages,
                selectedImages,
                priceTableGlobal,
                stagingSaved,
                selectedFrame,
                insertSelectedFrame,
                setPriceTableGlobal,
                insertSelectedImages,
                insertNewImages,
                patchImages,
                saveStaging,
                setOpenRegistrationModal,
                // ALBUM FLOW
                collagePhotos,
                setCollagePhotos
            }}
        >
            {props.children}
        </EditorContext.Provider>
    );
}