import React, { useState, createContext } from "react";
import { useHistory } from "react-router-dom";

export const AuthContext = createContext({
    isLoggedIn: false,
    user: null,
    orderData: null,
    setOrderDataHelper: () => {},
    login: () => {},
    logout: () => {}
});

export const AuthContextProvider = (props) => {

    const history = useHistory();

    const [user, setUser] = useState(null);
    const [orderData, setOrderData] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const setOrderDataHelper = (data) => setOrderData(data);

    const login = (user, redirect) => {

        localStorage.setItem("user", JSON.stringify(user));
        setUser(user);
        setIsLoggedIn(true);

        if (redirect) {
            history.push("/");
        }

    }

    const logout = (redirect, url = "/") => {

        localStorage.removeItem("user");

        setUser(null);
        setIsLoggedIn(false);

        if (redirect) {
            history.push(url);
        }

    }
    
    return (
        <AuthContext.Provider 
            value={{
                isLoggedIn,
                user,
                login,
                logout,
                orderData,
                setOrderDataHelper
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
}