/* eslint-disable no-useless-escape */
import React, { Fragment, useContext } from "react";
import { Button, Header, Modal, Grid } from "semantic-ui-react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../../utils/axiosInstance";

import { AuthContext } from "../../shared/context/auth-context";

import InputC from "../../components/InputC/InputC";

const LoginModal = (props) => {

    const { setContext } = props;

    const auth = useContext(AuthContext);

    const initialValues = {
        login_key: "",
        password: "",
        showPassword: false
    }

    const handleSubmit = async (values, fr) => {

        try {

            const postData = { ...values };
            delete postData.showPassword;

            const { data } = await axios.post("/auth/sign-in", postData);

            if (data.loginSuccess) {

                fr.setSubmitting(false);

                auth.login({
                    u_id: data.u_id,
                    full_name: data.full_name,
                    email: data.email,
                    token: data.token
                }, false);

                toast.success("Successfully logged in!");

            } else {
                fr.setSubmitting(false);
                toast.error(data.msg);
            }

        } catch (err) {
            console.log(err.response);
        }
    }

    const schema = yup.object().shape({
        login_key: yup.string()
            .required("Email/Phone No. is required")
            .test("key_test", "Enter Valid Phone/Email",
                function (value) {

                    const emailRe = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                    const phoneRe = /^(?:\+88|01)?(?:\d{11}|\d{13})$/;

                    let isValidEmail = emailRe.test(value);
                    let isValidPhone = phoneRe.test(value);

                    if (!isValidEmail && !isValidPhone) {
                        return false;
                    }

                    return true;
                }),
        password: yup.string().min(6, "Password must be at least 6 characters long").required("Password is required!"),
    });

    return (
        <Fragment>
            <Header size="large">
                Welcome Back
                <Header.Subheader>
                    Login to continue
                </Header.Subheader>
            </Header>
            <Modal.Content>
                <Modal.Description>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={schema}
                        onSubmit={handleSubmit}
                    >
                        {(fr) => (
                            <Form>
                                <InputC
                                    size="big"
                                    fluid name="login_key"
                                    placeholder="Enter your email or phone no."
                                    type="text"
                                    onChange={fr.handleChange}
                                    onBlur={fr.handleBlur}
                                    error={fr.errors.login_key && fr.touched.login_key}
                                    errorMsg={fr.errors.login_key}
                                />

                                <InputC
                                    icon={{
                                        name: fr.values.showPassword ? "eye slash" : "eye", link: true,
                                        onClick: () => fr.setFieldValue("showPassword", !fr.values.showPassword)
                                    }}
                                    autoComplete="off"
                                    size="big"
                                    fluid name="password"
                                    placeholder="Enter your password"
                                    type={fr.values.showPassword ? "text" : "password"}
                                    onChange={fr.handleChange}
                                    onBlur={fr.handleBlur}
                                    error={fr.errors.password && fr.touched.password}
                                    errorMsg={fr.errors.password}
                                />
                                <Button
                                    color="yellow"
                                    fluid
                                    size="big"
                                    disabled={fr.isSubmitting}
                                    loading={fr.isSubmitting}
                                >
                                    Login
                                </Button>
                                <div className="Login--links">
                                    <Grid columns="equal">
                                        <Grid.Column>
                                            <span className="Register--links" onClick={() => setContext("register")}>
                                                Don't have an account? {" "}
                                                Register here
                                            </span>
                                        </Grid.Column>
                                        <Grid.Column textAlign="right">
                                            <span>
                                                <Link to={{ pathname: "/account-recovery", state: { pussy: 123 } }}>
                                                    Forgot Password?
                                                </Link>
                                            </span>
                                        </Grid.Column>
                                    </Grid>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Description>
            </Modal.Content>
        </Fragment>
    );
}

export default LoginModal;