import React, { createContext, useState } from "react";

export const UtilContext = createContext({
    sizeChartOpen: false,
    inviteModalOpen: false,
    openSizeChart: () => {},
    closeSizeChart: () => {},
    inviteModalControl: () => {},

    uploaderContext: "",
    setUploaderContext: () => {},
    method: "",
    setMethod: () => {}
});

export const UtilContextProvider = (props) => {

    const [sizeChartOpen, setSizeChartOpen] = useState(false);
    const [inviteModalOpen, setInviteModalOpen] = useState(false);

    const [uploaderContext, setUploaderContext] = useState("method");
    const [method, setMethod] = useState("");
    
    const openSizeChart = () => setSizeChartOpen(true);
    const closeSizeChart = () => setSizeChartOpen(false);
    const inviteModalControl = (ctrl) => setInviteModalOpen(ctrl);
    
    return (
        <UtilContext.Provider 
            value={{ 
                sizeChartOpen,
                inviteModalOpen,
                openSizeChart,
                closeSizeChart,
                inviteModalControl,

                uploaderContext,
                setUploaderContext,
                method,
                setMethod
            }}
        >
            {props.children}
        </UtilContext.Provider>
    );
}