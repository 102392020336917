// eslint-disable-next-line no-unused-vars
//const dev = "http://localhost:5000/";
const prod = "https://api.printjoldi.com/";

export const API_ROOT = prod;

export const S3_BASE = "https://printjoldi-cdn.s3-ap-southeast-1.amazonaws.com";

export const paymentStatus = {
  PAID: 1,
  REJECTED: 2,
  CANCELLED: 3,
  UNPAID: 4,
};

export const printSizesLand = [
  {
    name: '6"x4"',
    value: "6_4",
    size: "Small (S)",
    short: "S",
    price: 15,
  },
  {
    name: '7.5"x5"',
    value: "7.5_5",
    size: "Medium (M)",
    short: "M",
    price: 50,
  },
  {
    name: '9"x6"',
    value: "9_6",
    size: "Large (L)",
    short: "L",
    price: 65,
  },
  {
    name: '12"x8"',
    value: "12_8",
    size: "Extra Large (XL)",
    short: "XL",
    price: 130,
  },
  {
    name: '18"x12"',
    value: "18_12",
    size: "Extra Extra Large (XXL)",
    short: "XXL",
    price: 250,
  },
];

export const printSizesSquare = [
  {
    name: '4"x4"',
    value: "4_4",
    size: "Small (S)",
    short: "S",
    price: 15,
  },
  {
    name: '5"x5"',
    value: "5_5",
    size: "Medium (M)",
    short: "M",
    price: 50,
  },
  {
    name: '6"x6"',
    value: "6_6",
    size: "Large (L)",
    short: "L",
    price: 65,
  },
  {
    name: '8"x8"',
    value: "8_8",
    size: "Extra Large (XL)",
    short: "XL",
    price: 130,
  },
  {
    name: '12"x12"',
    value: "12_12",
    size: "Extra Extra Large (XXL)",
    short: "XXL",
    price: 250,
  },
];

export const printSizes = [
  {
    name: '4"x6"',
    value: "4_6",
    size: "Small (S)",
    short: "S",
    price: 15,
  },
  {
    name: '5"x7.5"',
    value: "5_7.5",
    size: "Medium (M)",
    short: "M",
    price: 50,
  },
  {
    name: '6"x9"',
    value: "6_9",
    size: "Large (L)",
    short: "L",
    price: 65,
  },
  {
    name: '8"x12"',
    value: "8_12",
    size: "Extra Large (XL)",
    short: "XL",
    price: 130,
  },
  {
    name: '12"x18"',
    value: "12_18",
    size: "Extra Extra Large (XXL)",
    short: "XXL",
    price: 250,
  },
];

export const frameSizes = [
  {
    name: '8"x12"',
    value: "8_12",
    basePrice: 600,
    glassPrice: 115,
    mountPrice: 300,
  },
  {
    name: '12"x18"',
    value: "12_18",
    basePrice: 900,
    glassPrice: 225,
    mountPrice: 500,
  },
  {
    name: '16"x24"',
    value: "16_24",
    basePrice: 1998,
    glassPrice: 550,
    mountPrice: 1002,
  },
  {
    name: '20"x30"',
    value: "20_30",
    basePrice: 2700,
    glassPrice: 675,
    mountPrice: 1300,
  },
  {
    name: '24"x36"',
    value: "24_36",
    basePrice: 3998,
    glassPrice: 1050,
    mountPrice: 1602,
  },
];

export const frameSizesLandscape = [
  {
    name: '12"x8"',
    value: "12_8",
    basePrice: 600,
    glassPrice: 115,
    mountPrice: 300,
  },
  {
    name: '18"x12"',
    value: "18_12",
    basePrice: 900,
    glassPrice: 225,
    mountPrice: 500,
  },
  {
    name: '24"x16"',
    value: "24_16",
    basePrice: 1998,
    glassPrice: 550,
    mountPrice: 1002,
  },
  {
    name: '30"x20"',
    value: "30_20",
    basePrice: 2700,
    glassPrice: 675,
    mountPrice: 1300,
  },
  {
    name: '36"x24"',
    value: "36_24",
    basePrice: 3998,
    glassPrice: 1050,
    mountPrice: 1602,
  },
];

export const frameSizesSquare = [
  {
    name: '8"x8"',
    value: "8_8",
    basePrice: 600,
    glassPrice: 115,
    mountPrice: 300,
  },
  {
    name: '12"x12"',
    value: "12_12",
    basePrice: 900,
    glassPrice: 225,
    mountPrice: 500,
  },
  {
    name: '16"x16"',
    value: "16_16",
    basePrice: 1998,
    glassPrice: 550,
    mountPrice: 1002,
  },
  {
    name: '20"x20"',
    value: "20_20",
    basePrice: 2700,
    glassPrice: 675,
    mountPrice: 1300,
  },
  {
    name: '24"x24"',
    value: "12_24",
    basePrice: 3998,
    glassPrice: 1050,
    mountPrice: 1602,
  },
];

export const deepCopyArrayFile = (arr, meta) => {
  const files = arr.map((a) => {
    const file = new File([a], a.name, { type: a.type });

    if (meta) {
      file.id = a.id;
      file.dimension = a.dimension;
      file.preview = a.preview;
    }

    return file;
  });

  return files;
};
