import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { Loader } from "semantic-ui-react";

import Layout from "./layout/Layout";

const Home = React.lazy(() => import("./pages/Home/Home"));
const ImageEditor = React.lazy(() => import("./pages/ImageEditor/ImageEditor"));
const ImageLib = React.lazy(() => import("./pages/ImageLib/ImageLib"));
const ImageFrame = React.lazy(() => import("./pages/ImageFrame/ImageFrame"));
const ImageStaging = React.lazy(() => import("./pages/ImageStaging/ImageStaging"));
const StagingAlbum = React.lazy(() => import("./pages/ImageStaging/StagingAlbum"));
const Blog = React.lazy(() => import("./pages/Blog/Blog"));
const BlogView = React.lazy(() => import("./pages/BlogView/BlogView"));
const Checkout = React.lazy(() => import("./pages/Checkout/Checkout"));
const CheckOutAlbum = React.lazy(() => import("./pages/CheckOutAlbum/CheckOutAlbum"));
const CheckoutCollage = React.lazy(() => import("./pages/Checkout/CheckoutCollage"));
const Collages = React.lazy(() => import("./pages/Collages/Collages"));
const BillingIPN = React.lazy(() => import("./pages/BillingIPN/BillingIPN"));
const FAQ = React.lazy(() => import("./pages/Common/FAQ"));
const Terms = React.lazy(() => import("./pages/Common/Terms"));
const Privacy = React.lazy(() => import("./pages/Common/Privacy"));
const Price = React.lazy(() => import("./pages/Price/Price"));
const ContactUs = React.lazy(() => import("./pages/ContactUs/ContactUs"));
const NotFound = React.lazy(() => import("./pages/NotFound/NotFound"));

const App = () => {

    let allowedRoutes = (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/blogs" component={Blog} />
            <Route exact path="/blog-details" component={BlogView} />
            <Route exact path="/contact" component={ContactUs} />

            <Route exact path="/editor" component={ImageEditor} />
            <Route exact path="/frames" component={ImageFrame} />
            <Route exact path="/album" component={StagingAlbum} />
            <Route exact path="/photo-library" component={ImageLib} />
            <Route exact path="/photo-staging" component={ImageStaging} />
            <Route exact path="/checkout" component={Checkout} />
            <Route exact path="/checkout-album" component={CheckOutAlbum} />
            <Route exact path="/checkout-collage" component={CheckoutCollage} />
            <Route exact path="/payment-confirmation" component={BillingIPN} />
            <Route exact path="/collages" component={Collages} />
            
            <Route exact path="/faq" component={FAQ} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/prices" component={Price} />

            <Route path="*" exact component={NotFound} />
        </Switch>
    );

    return (
        <Layout>
            <React.Suspense fallback={<Loader size="massive" active />}>
                {allowedRoutes}
            </React.Suspense>
        </Layout>
    );
}

export default withRouter(App);
