import React, { useRef, useContext } from "react";
import { Modal, Header, Image, Input, Divider } from "semantic-ui-react";
import { EmailShareButton, FacebookShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { toast } from "react-toastify";

import { UtilContext } from "../../shared/context/util-context";

import SocialFB from "../../assets/social_fb.svg";
import SocialWhatsApp from "../../assets/social_whatsapp.svg";
import SocialTwitter from "../../assets/social_twitter.svg";
import SocialEmail from "../../assets/email.svg";

import "./InviteModal.scss";

const InviteModal = () => {

    const { inviteModalOpen, inviteModalControl } = useContext(UtilContext);
    const copyRef = useRef(null);

    const copyLinkToClipBoard = () => {

        copyRef.current.select();
        document.execCommand("copy");
        toast.info("Link Copied");
    }

	return (
		<Modal
			closeIcon
            open={inviteModalOpen}
            onClose={() => inviteModalControl(false)}
			size="small"
		>
			<Header size="large">
				Invite Friends
                <Header.Subheader>
					Invite friends to PrintJoldi
                </Header.Subheader>
			</Header>
			<Modal.Content>
				<Modal.Description>
                    <div className="InviteModal--container">
                        <div className="ShareButton">
                            <EmailShareButton subject="Invitation to PrintJoldi.com" body="Hey, get your photos printed and framed at https://printjoldi.com/">
                                <div className="InviteIcon">
                                    <Image size="mini" src={SocialEmail} />
                                    <p>Email</p>
                                </div>
                            </EmailShareButton>
                        </div>

                        <div className="ShareButton">
                            <FacebookShareButton quote="Hey, get your photos printed and framed at" url="https://printjoldi.com/">
                                <div className="InviteIcon">
                                    <Image size="mini" src={SocialFB} />
                                    <p>Facebook</p>
                                </div>
                            </FacebookShareButton>
                        </div>

                        <div className="ShareButton">
                            <TwitterShareButton title="Hey, get your photos printed and framed at" url="https://printjoldi.com/">
                                <div className="InviteIcon">
                                    <Image size="mini" src={SocialTwitter} />
                                    <p>Twitter</p>
                                </div>
                            </TwitterShareButton>
                        </div>

                        <div className="ShareButton">
                            <WhatsappShareButton title="Hey, get your photos printed and framed at" separator=" " url="https://printjoldi.com/">
                                <div className="InviteIcon">
                                    <Image size="mini" src={SocialWhatsApp} />
                                    <p>WhatsApp</p>
                                </div>
                            </WhatsappShareButton>
                        </div>
                    </div>
                    <Divider />
                    <div className="CopyContainer">
                        <Header size="small">Copy Link</Header>
                        <Input
                            ref={copyRef}
                            action={{
                                color: "yellow",
                                labelPosition: "right",
                                icon: "copy",
                                content: "Copy",
                                onClick: copyLinkToClipBoard
                            }}
                            value="https://printjoldi.com/"
                        />
                    </div>
				</Modal.Description>
			</Modal.Content>
		</Modal>
	);
}

export default InviteModal;