import React, { useState, useContext } from "react";
import { Modal } from "semantic-ui-react";

import RegisterModal from "./RegisterModal";
import LoginModal from "./LoginModal";

import { EditorContext } from "../../shared/context/editor-context";

import "./RegisterModal.scss";

const Account = () => {

    const { openRegistrationModal, setOpenRegistrationModal } = useContext(EditorContext);

    const [context, setContext] = useState("register");

    const onClose = () => {
        setContext("register");
        setOpenRegistrationModal(false);
    }

    return (
        <Modal
            closeIcon
            size="small"
            open={openRegistrationModal}
            onClose={onClose}
        >
            {
                context === "register" ?
                <RegisterModal setContext={setContext} /> :
                context === "login" ?
                <LoginModal setContext={setContext} /> : null
            }
        </Modal>
    );
}

export default Account;