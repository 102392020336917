import React, { Fragment } from "react";
import { Input, Icon } from "semantic-ui-react";

const InputC = (props) => {

    const { 
        name,
        icon, 
        type,
        onBlur, 
        onChange, 
        label, 
        error, 
        errorMsg, 
        fluid,
        size,
        placeholder,
        autoComplete
    } = props;

    return (
        <div className="accounts-input">
            {label && <label className="util-bold">{label}</label>}
            <Input
                autoComplete={autoComplete}
                icon={icon ? icon : null}
                size={size ? size: "small"}
                fluid={fluid}
                name={name}
                placeholder={placeholder}
                type={type}
                onChange={onChange}
                onBlur={onBlur}
                error={error}
            />
            <p className="input-field-error">
                {
                    error &&
                    <Fragment>
                        <Icon name="warning sign" />
                        {errorMsg}
                    </Fragment>
                }
            </p>
        </div>
    );
}

export default InputC;